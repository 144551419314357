
import Vue from 'vue'
import Component from 'vue-class-component'
import AdminPartnerForm from '@/views/Admin/Partners/components/AdminPartnerForm.vue'
import Partners from '@/apis/Partners'

@Component({
  name: 'AddPartner',
  components: {
    AdminPartnerForm
  }
})

export default class AddPartner extends Vue {
  brands = []
  networks = []
  cities = []
  regions = []

  partnerDetails = {
    categoryId: null,
    networkId: null,
    name: null,
    brandIds: [],
    emailDomain: null,
    corpDomain: null,
    is_trial: '0',
    location: {
      address: null,
      cityId: null,
      regionId: null,
      lon: null,
      lat: null
    }
  }

  created() {
    this.fetchComboboxesForPartner()
  }

  async fetchComboboxesForPartner() {
    await Partners.fetchComboboxesForPartner()
      .then(({ data }) => {
        this.brands = data.brands
        this.networks = data.networks
        this.cities = data.cities
        this.regions = data.regions
      })
  }

  storePartner() {
    Partners.storePartner(this.partnerDetails)
      .then(({ data }) => {
        if (data.success === true) {
          // @ts-ignore
          this.showSuccessSystemNotification(data.msg)
          this.$router.push({ name: 'Partners' })
        } else {
          // @ts-ignore
          this.showErrorSystemNotification(data.msg)
        }
      })
      .catch(() => {
        // @ts-ignore
        this.showErrorSystemNotification('Заполните поля для добавления нового партнера')
      })
  }
}
